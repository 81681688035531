
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';

	import LibraryTemplateDetails from '@/components/pixcap-library/library-templates/LibraryTemplateDetails.vue';
	import { IAssetsLibraryState, NAMESPACE as LIBRARY_NAMESPACE } from '@/models/store/pixcapassets.interface';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';

	@Component({
		name: 'LibraryTemplateDetailsModal',
		components: {
			LibraryTemplateDetails,
			BaseModal,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				selectedLibraryTemplateSlug: (state: IAssetsLibraryState) => state.selectedLibraryTemplateSlug,
			}),
		},
	})
	export default class LibraryTemplateDetailsModal extends Vue {
		selectedLibraryTemplateSlug: IAssetsLibraryState['selectedLibraryTemplateSlug'];
		_handlePressStateButton;

		$refs: {
			refLibraryTemplateDetailsModal;
		};

		@Watch('selectedLibraryTemplateSlug')
		onItemSlugChange() {
			(this.$refs.refLibraryTemplateDetailsModal.$el as HTMLElement).scrollTop = 0;
		}

		handleClose() {
			LibraryMutations.setSelectedLibraryTemplateSlug(this.$store, null);
			const backIndex = window.history.state.currentItemIndex;
			window.history.go(backIndex);
		}

		handlePressStateButton() {
			const locationPath = window.location.pathname;
			const routePath = this.$route.path;
			if (locationPath === routePath) LibraryMutations.setSelectedLibraryTemplateSlug(this.$store, null);
		}

		created() {
			this._handlePressStateButton = this.handlePressStateButton.bind(this);
			window.addEventListener('popstate', this._handlePressStateButton);
		}

		mounted() {
			this.$nextTick(() => {
				this.$refs.refLibraryTemplateDetailsModal.$el.focus();
			});
		}

		beforeDestroy() {
			window.removeEventListener('popstate', this._handlePressStateButton);
		}
	}
