import { render, staticRenderFns } from "./PackRecommender.vue?vue&type=template&id=28b964f6&scoped=true&"
import script from "./PackRecommender.vue?vue&type=script&lang=ts&"
export * from "./PackRecommender.vue?vue&type=script&lang=ts&"
import style0 from "./PackRecommender.vue?vue&type=style&index=0&id=28b964f6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b964f6",
  null
  
)

export default component.exports