
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import { NAMESPACE as LIBRARY_NAMESPACE, IAssetsLibraryState } from '@/models/store/pixcapassets.interface';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import LibraryItemDetails from '@/components/pixcap-library/LibraryItemDetails.vue';

	@Component({
		name: 'LibraryItemDetailsModal',
		components: {
			LibraryItemDetails,
			BaseModal,
			IconClose,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				selectedLibraryItemSlug: (state: IAssetsLibraryState) => state.selectedLibraryItemSlug,
			}),
		},
	})
	export default class LibraryItemDetailsModal extends Vue {
		selectedLibraryItemSlug: IAssetsLibraryState['selectedLibraryItemSlug'];
		_handlePressStateButton;

		$refs: {
			refItemDetailsModal;
		};

		@Watch('selectedLibraryItemSlug')
		onItemSlugChange() {
			(this.$refs.refItemDetailsModal.$el as HTMLElement).scrollTop = 0;
		}

		handleClose() {
			LibraryMutations.setSelectedLibraryItemSlug(this.$store, null);
			const backIndex = window.history.state.currentItemIndex;
			window.history.go(backIndex);
		}

		handlePressStateButton() {
			const locationPath = window.location.pathname;
			const routePath = this.$route.path;
			if (locationPath === routePath) LibraryMutations.setSelectedLibraryItemSlug(this.$store, null);
		}

		created() {
			this._handlePressStateButton = this.handlePressStateButton.bind(this);
			window.addEventListener('popstate', this._handlePressStateButton);
		}

		mounted() {
			this.$nextTick(() => {
				this.$refs.refItemDetailsModal.$el.focus();
			});
		}

		beforeDestroy() {
			window.removeEventListener('popstate', this._handlePressStateButton);
		}
	}
